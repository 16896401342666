<template>
    <div class="notAutoCancelSellOut">
        <div class="main-flex">
            <edit-btns class="btn-box" 
            :isOrder="true"
            :bnts="[{key:'add',text:'添加菜品'},'batchDelete','output','goBack']"
            @addClick="addFood()"
            @outputClick="exportExcel()"
            @batchDeleteClick="delKitFood()"
            @goBackClick="$router.back()"/>
        </div>
        <div class="content-box">
            <!-- <div class="btn-box">
                <div class="title">设置不自动取消沽清</div>
                <div class="right-bnt">
                    <div class="bnt excel-bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出 </div>
                    <div class="bnt del-bnt" @click="delKitFood()"><i class="iconfont icon-shanchu"></i>批量删除</div>
                </div>
            </div> -->
            <div class="table-box" ref="foodsTable">
                <el-table class="el-table--scrollable-y" ref="kitFoods" style="width: 100%;height:100%"  
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                :data="pagetempFoods"
                @selection-change="(val)=>{multipleFoodsTable=val}"
                >
                    <el-table-column label="序号" width="50" type="index" :index="(index)=>index+(pageIndex-1)*pagesize+1" />
                    <el-table-column type="selection" width="40" />
                    <el-table-column prop="Food_Code" label="菜品编码" min-width="80" ></el-table-column>
                    <el-table-column prop="Food_Name" label="菜品名称" min-width="100" ></el-table-column>
                    <el-table-column prop="Food_Size" label="单位" min-width="100" ></el-table-column>
                    <el-table-column prop="SubCategory_Name" label="小类名称" min-width="80" ></el-table-column>
                    <el-table-column label="操作" width="60">
                        <template #default="scope">
                            <i class="iconfont icon-shanchu" @click="delKitFood(scope.row,scope.$index)"></i>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="bottom-search-box">
                <!-- <div class="add-bnt" @click="addFood()"><i class="iconfont icon-jia"></i>添加菜品</div> -->
                <div class="fill-box"></div>
                <div class="search-box">
                    <key-board-input class="search"
                        type="text" v-model="foodText" placeholder="菜品模糊检索"
                        isBoard isKeyEnterClose 
                        :keyOptions="{isKeyDownEnter:true}"
                    ></key-board-input>
                </div>
                <div class="page-box-bottom">
                    <page-turning class="last-page" :pageCount="tempFoodsPageCount" v-model="pageIndex" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                    <span class="page-number">{{pageIndex}}/{{tempFoodsPageCount}}页</span>
                    <page-turning class="next-page" :pageCount="tempFoodsPageCount" v-model="pageIndex"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                </div>
            </div>
        </div>
        <!-- 选择菜品 -->
        <modal-load :isShow="foodIsShow" :isUnload="true">
            <food-model :isShow="foodIsShow" :treeData="treeData" title="添加菜品" :selectList="soldoutFoods" @closeModel="foodIsShow=false" @confirm="KitNameBindFood"></food-model>
        </modal-load>
    </div>
</template>

<script>
import foodModel from '../saveSellOutMould/foodModel/foodModel.vue'
import XLSX from 'xlsx'

//设置不自动取消沽清
export default {
    name:"notAutoCancelSellOut",
    components:{foodModel},
    data(){
        return {
            //不自动取消沽清 的菜品数据 只有菜品id
            foodIds:[],
            //不自动取消沽清 的菜品数据
            soldoutFoods:[],
            pageIndex:1,
            pagesize:5,
            //菜品数据
            posFoods:[],
            //多选菜品
            multipleFoodsTable:[],
            //选择菜品 弹层
            foodIsShow:false,
            foodText:""
        }
    },
    computed:{
        //模板绑定的菜品 筛选数据
        tempFoodsFiler(){
            let data=this.soldoutFoods||[];
            let text=(this.foodText||"").trim().toLowerCase();
            if(text){
                data= data.filter(it=> (it.Food_Name||"").toLowerCase().indexOf(text)>=0 
                || (it.Food_PinYin||"").toLowerCase().indexOf(text)>=0 
                || (it.Food_Code||"").toLowerCase().indexOf(text)>=0);
            }
            return data;
        },
        /**分页数据 */
        pagetempFoods(){
            return this.tempFoodsFiler.slice((this.pageIndex-1)*this.pagesize,this.pageIndex*this.pagesize);
        },
        ///菜品总页数
        tempFoodsPageCount(){
            if(this.tempFoodsFiler?.length>0){
                return Math.ceil(this.tempFoodsFiler.length/this.pagesize);
            }
            return 1;
        },
        treeData(){
            let list=[];
            this.posFoods?.forEach((it)=>{
                it.FoodSubCategorys?.forEach((f)=>{
                    let children=f.PosFoods?.map((foods)=>{
                        return {
                            id: foods.Food_ID,
                            label: foods.Food_Name,
                            Food_Code:foods.Food_Code,
                            Food_Name:foods.Food_Name,
                            Food_Size:foods.Food_Size,
                            Food_PinYin:foods.Food_PinYin,
                            SubCategory_ID:f.SubCategory_ID,
                            SubCategory_Code:f.SubCategory_Code,
                            SubCategory_Name:f.SubCategory_Name
                        }
                    })
                    if(children.length>0){
                        list.push({
                            id: "sub_"+f.SubCategory_ID,
                            label: f.SubCategory_Name,
                            children:children
                        })
                    }
                    
                })
            })
            return list;
        },
    },
    mounted(){
        //加载所有菜品数据
        this.$cacheData.AllPosFoods().then((d)=>{
            this.posFoods=d||[];
            this.setFoods();
        }).catch(e=>{
            console.log('e:',e);
            this.$message.error('未找到菜品数据');
        })
        
        this.$nextTick(()=>{
            this.loadData();
            this.setPageSize();
        });
        
    },
    watch:{
        currentInfo(){
            this.pageIndex=1;
        },
        kitFoodsPageCount(newVal){
            if(newVal<this.pageIndex){
                this.pageIndex=1;
            }
        },
        foodText(newVal){
            if(newVal){
                this.pageIndex=1;
            }
        },
    },
    methods:{
        /**计算 一页显示多少条数据 */
        setPageSize(){
            if(this.$refs.foodsTable){
                this.$nextTick(()=>{
                    let height=this.$refs.foodsTable.clientHeight - 30;
                    this.pagesize=parseInt(height/40);
                })
            }
        },
        //加载数据
        loadData(){
            const loading = this.$loading({
                text: "获取限量菜品设置模板数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetNotAutoCancelSoldoutFoods", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.foodIds=d.ResponseBody;
                    this.setFoods();
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("限量菜品设置模板数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        setFoods(){
            console.log("d")
            if(this.foodIds?.length>0 && this.posFoods.length>0){
                let data=[];
                this.posFoods?.forEach((it)=>{
                    it.FoodSubCategorys?.forEach((f)=>{
                        f.PosFoods?.forEach((foods)=>{
                            if(this.foodIds.indexOf(foods.Food_ID)>=0){
                                data.push(Object.assign({},foods,{
                                    SubCategory_Code:f.SubCategory_Code,
                                    SubCategory_Name:f.SubCategory_Name
                                }))
                            }
                        })
                    })
                })
                this.soldoutFoods=data;
            }
        },
        //添加菜品
        addFood(){
            this.foodIsShow=true;
        },
        //绑定菜品
        KitNameBindFood(list){
            if((list||[]).length==0){
                this.$message.warning("请先勾选要绑定的菜品!");
                return;
            }
            this.$confirm("您确认保存菜品信息吗?","提示", {
                confirmButtonText: "确认",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.setNotAutocancelsoldoutFoods(list)
                    }
                },
            })
        },
        //删除模板菜品
        delKitFood(row){
            let AutoIDs=[];
            if(row){
                AutoIDs=[row.Food_ID];
            }else if(this.multipleFoodsTable?.length>0){
                this.multipleFoodsTable.forEach(it=>{
                    AutoIDs.push(it.Food_ID);
                })
            }
            if(AutoIDs.length==0){
                this.$message.warning("请勾选要删除的菜品!");
                return;
            }
            this.$confirm(row?"您确认删除"+row.Food_Name+"吗？":"您确认删除模板绑定的菜品吗？","提示", {
                confirmButtonText: "确认",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.DelBindFood(AutoIDs);
                    }
                },
            })
        },
        //解除绑定菜品
        DelBindFood(AutoIDs){
            let list= this.soldoutFoods.filter(it=>AutoIDs.indexOf(it.Food_ID)<0);

            this.setNotAutocancelsoldoutFoods(list)
        },
        //设置不自动取消沽清的菜品
        setNotAutocancelsoldoutFoods(list){
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let foods=list.map(it=>it.Food_ID);
            let param={
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                FoodIds:foods
            }
            console.log(param)
            this.$httpAES.post("Bestech.CloudPos.SetNotAutoCancelSoldoutFoods",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.foodIsShow=false;
                    this.$message.success("保存成功");
                    this.soldoutFoods=list;
                    console.log("保存成功",d.ResponseBody)
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('保存失败：'+e.message);
                console.log('保存失败：',e);
            })
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            var newWorkbook = XLSX.utils.book_new();

            let arr=[['菜品编码','菜品名称','单位','小类名称']];
            this.soldoutFoods?.forEach(it=>{
                arr.push([it.Food_Code,it.Food_Name,it.Food_Size,it.SubCategory_Name])
            })
                
            let wb = XLSX.utils.aoa_to_sheet(arr);
            XLSX.utils.book_append_sheet(newWorkbook,wb,'不自动取消沽清信息');
            try {
                XLSX.writeFile(newWorkbook,"不自动取消沽清信息.xlsx")
            } catch (e) {
                if (typeof console !== 'undefined') console.log(e)
            }
        },
    }
}
</script>

<style lang="scss">
    @import './notAutoCancelSellOut.scss'
</style>